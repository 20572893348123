<template>
  <div>
    <supplier-items-form
      v-model="receiveItems"
      page-title="Receive New Inventory"
      :no-data-text="
        !loaded
          ? 'Loading items. Please wait...'
          : !supplierId || !orderGuideId
          ? 'Select an order guide to start receiving'
          : 'No items found'
      "
      quantity-label="Quantity Received"
      :additional-headers="additionalHeaders"
      :loaded="loaded"
    >
      <template v-slot:filters[0]>
        <custom-date-picker v-model="date" init dense showTime />
      </template>
      <template v-if="orderGuides.length > 1" v-slot:filters[1]>
        <custom-select
          v-model="orderGuideId"
          :items="orderGuides"
          item-text="name"
          item-value="id"
          label="Order Guide"
          dense
        />
      </template>
      <template v-slot:filters[2]>
        <custom-select
          v-model="selectedSubcategory"
          :items="[{ id: '', name: 'All Subcategories' }, ...subcategories]"
          item-text="name"
          item-value="id"
          label="Subcategory"
          :disabled="!orderGuideId"
          dense
        />
      </template>
    </supplier-items-form>
    <v-footer
      v-if="receiveItems.length"
      fixed
      elevation="3"
      class="fixed-btn-container py-4"
    >
      <v-btn
        :disabled="itemsReceived.length === 0"
        color="primary"
        class="fixed-btn"
        @click="submitForReview"
      >
        Review
      </v-btn>
    </v-footer>
    <review
      v-if="orderGuideId"
      :date="date"
      :orderGuide="selectedOrderGuide"
      :itemsReceived="itemsReceived"
      :purchaseOrder="purchaseOrder"
      @success="closeReview"
      @close="$router.push({ params: { form: null, id: null } })"
    />
  </div>
</template>

<script>
import SupplierItemsForm from "@/AuthenticatedContent/shared/forms/SupplierItemsForm.vue"
import Review from "./Review"
import CustomDatePicker from "@/AuthenticatedContent/shared/forms/datetime/CustomDatePicker.vue"
import CustomSelect from "@/AuthenticatedContent/shared/CustomSelect.vue"
import topLevelMixins from "@/AuthenticatedContent/mixins.js"
import { mapState, mapGetters } from "vuex"

export default {
  name: "receive-new",
  components: {
    SupplierItemsForm,
    Review,
    CustomDatePicker,
    CustomSelect,
  },
  mixins: [topLevelMixins],
  data() {
    return {
      loaded: false,
      date: "",
      selectedSubcategory: "",
      orderGuideId: "",
      receiveItems: [],
    }
  },
  computed: {
    ...mapGetters(["getUnitName"]),
    ...mapState([
      "orderGuides",
      "supplierItems",
      "subcategories",
      "purchaseOrders",
      "units",
      "items",
    ]),
    selectedOrderGuide() {
      return this.orderGuides.find(og => og.id == this.orderGuideId)
    },
    supplierId() {
      return this.selectedOrderGuide?.supplierId
    },
    purchaseOrderId() {
      return this.$route.query?.purchaseOrderId || ""
    },
    purchaseOrder() {
      return this.purchaseOrders.find(order => order.id == this.purchaseOrderId)
    },
    additionalHeaders() {
      if (this.purchaseOrder && Object.keys(this.purchaseOrder).length > 0) {
        return [{ text: "Quantity Ordered", value: "orderedStock", width: 150 }]
      } else {
        return []
      }
    },
    itemsReceived() {
      return this.receiveItems.filter(item => item.quantity > 0)
    },
  },
  async mounted() {
    if (this.orderGuides.length == 1) {
      this.orderGuideId = this.orderGuides[0].id
    }
    this.filterItems()
    this.loaded = true
  },
  methods: {
    filterItems() {
      var finalItems = []
      if (this.orderGuideId != "" && this.supplierId != "") {
        var itemsFromSupplier = this.supplierItems.filter(
          sItem =>
            sItem.supplierId == this.supplierId &&
            sItem.orderGuideId == this.orderGuideId
        )
        itemsFromSupplier.forEach(item => {
          if (!this.selectedSubcategory) {
            finalItems.push(item)
          } else {
            var linkedInvItem = this.items.find(
              invItem => invItem.itemID == item.linkedItemID
            )
            if (
              linkedInvItem != undefined &&
              linkedInvItem.subcategory == this.selectedSubcategory
            ) {
              finalItems.push(item)
            }
          }
        })
        finalItems.forEach(item => {
          if (!item.quantityUnit) {
            this.$set(item, "quantityUnit", "pkgs")
          }
        })
        if (this.purchaseOrder) {
          finalItems = this.updateItemsFromPurchaseOrder(finalItems)
        }
        this.receiveItems = finalItems
      } else {
        this.receiveItems = []
      }
    },
    updateItemsFromPurchaseOrder(items) {
      const updatedItems = items.map(item => {
        const existingItem = this.purchaseOrder.purchaseOrderItems.find(
          i => i.supplierItemId === item.id
        )
        item.quantity = existingItem?.quantity || 0
        item.quantityUnit = existingItem?.quantityUnit || "pkgs"
        return item
      })
      return updatedItems
    },
    submitForReview() {
      this.$router.push({ params: { form: "Review" }, query: null })
    },
    closeReview() {
      // handle closing of the review form (reset all quantity values)
      this.receiveItems.forEach(item => {
        delete item.quantity
        delete item.packageCost
        delete item.quantityUnit
      })
      this.$router.push({
        params: { tab: "history", id: null },
        query: null,
      })
    },
  },
}
</script>

<style scoped lang="scss">
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 200;
}
</style>
