<template>
  <div>
    <page-component
      pageTitle="Receiving History"
      :headers="headers"
      :items="receivings"
      :loadingData="loading"
      sortDesc
      :buttons="[{ text: '+ Receive New Inventory', to: '/receive/AddEditForm' }]"
    >
      <template v-slot:[`item.date`]="{ item }">
        {{ getReceivingDate(item.date) }}
        <br />
        <span style="color: var(--v-lighttext-base)">
          {{ getReceivingTime(item.date) }}
        </span>
      </template>
      <template v-slot:[`item.orderGuide`]="{ item }">
        {{ getOrderGuide(item.orderGuideId).name }}
      </template>
      <template v-slot:[`item.supplier`]="{ item }">
        {{ getSupplierName(getOrderGuide(item.orderGuideId).supplierId) }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <nxg-action-btn type="eye" @click="viewReceiving(item)" />
      </template>
      <template v-slot:[`item.totalCost`]="{ item }">
        ${{ item.totalCost.toFixed(2) }}
      </template>
      <template v-slot:[`item.user`]="{ item }">
        {{ getUserDisplayName(item.userId) }}
      </template>
    </page-component>
    <review
      v-if="selectedReceivingId"
      historyView
      :date="getDateLabel(selectedReceiving.date)"
      :orderGuide="getOrderGuide(selectedReceiving.orderGuideId)"
      :itemsReceived="selectedReceiving.itemsReceived"
      :comments="selectedReceiving.comment"
      @close="$router.push({ params: { tab: 'history', form: null, id: null } })"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex"
import Review from "./Review"
import PageComponent from "@/AuthenticatedContent/shared/PageComponent.vue"
import moment from "moment"

export default {
  name: "receiving-history",
  components: { PageComponent, Review },
  data() {
    return {
      total: "",
      headers: [
        { text: "Date", value: "date" },
        { text: "Order Guide", value: "orderGuide" },
        { text: "Supplier", value: "supplier" },
        { text: "Total Cost", value: "totalCost" },
        { text: "User", value: "user" },
        { text: "Actions", value: "actions" },
      ],
      loading: true,
    }
  },
  computed: {
    ...mapGetters(["getSupplierName", "getUnitName"]),
    ...mapState([
      "orderGuides",
      "restaurants",
      "firebaseRefs",
      "users",
      "items",
      "receivings",
    ]),
    selectedReceivingId() {
      return this.$route.params.id
    },
    selectedReceiving() {
      return this.receivings.find(
        receiving => receiving.id === this.selectedReceivingId
      )
    },
  },
  async mounted() {
    await this.fetchReceivings()
    this.loading = false
  },
  methods: {
    ...mapActions(["fetchReceivings"]),
    getRestaurantName(restaurantID) {
      return this.restaurants.find(item => item.id == restaurantID).name
    },
    getOrderGuide(orderGuideID) {
      return this.orderGuides.find(item => item.id == orderGuideID)
    },
    getUserDisplayName(userId) {
      if (this.users) {
        const userDoc = Object.values(this.users)?.find(u => u.userId === userId)
        if (userDoc?.name) return userDoc.name
      }
      return "N/A"
    },
    getReceivingDate(date) {
      return moment(date.toDate()).format("MMM DD, YYYY")
    },
    getReceivingTime(date) {
      return moment(date.toDate()).format("h:mm A")
    },
    getDateLabel(date) {
      return moment(date.toDate()).format("YYYY-MM-DD HH:mm:ss")
    },
    viewReceiving(receiving) {
      this.$router.push({ params: { form: "Review", id: receiving.id } })
    },
  },
}
</script>
